import React, { useState, useEffect, useRef, useContext } from 'react';
import { Container } from '../styles/styles';
import {useNavigation, useRoute } from '@react-navigation/native'
import { FontAwesome5 } from '@expo/vector-icons';
import io from 'socket.io-client'
import {Platform, KeyboardAvoidingView } from 'react-native'
import styled from 'styled-components/native'
import moment from "moment/min/moment-with-locales"


import Api from '../Api'
import { UserContext } from '../contexts/UserContext';


const InputArea = styled.SafeAreaView`
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 5px
    flex-direction: row;
`;

const Input = styled.TextInput`
    font-size: 16px;
    color: #FFFFFF;
    backgroundColor: 'rgba(52, 52, 52, 0.1)'  
    width: 80%;
    border-radius: 15px;
    padding: 15px;
`;

const HeaderText = styled.Text`
    font-size: 16px;
    color: #FFF;
    padding: 7px
`;

const ChatText = styled.Text`
    color: #FFF;
    padding: 7px
    border-radius: 10px;
    margin-bottom: 7px;
    min-width: 15%
    max-width: 40%
`;


const ChatView = styled.View`
    width: 100%;
    padding: 10px

    
`;

const ChatArea = styled.View`
    width: 100%;
`;


const HeaderArea = styled.View`
    margin-top: 20px;
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
`;



const SendButton = styled.TouchableOpacity`
    height: 40px;
    border: 1px;
    width: 40px;
    borderColor: #FFFFFF;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    margin-left: 10px

`;


const Scroller = styled.ScrollView`
    flex: 1;
    width: 90%
    backgroundColor: 'rgba(52, 52, 52, 0.1)'
    border-radius: 15px;
    margin-top: 15px;
    margin-bottom: 10px;
    
`;

const GoBackButton = styled.TouchableOpacity`
    height: 40px;
    flex-direction: row;
    align-items: center;

`;


export default () => {

    const { state:user } = useContext(UserContext)

    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([])
    const [status, setStatus] = useState('true')
    const [tokens, setTokens] = useState('')


    const inputChat = useRef(null)
    const scrollViewRef = useRef(null)



    const navigation = useNavigation()
    const route = useRoute()

    moment.locale('pt-br')


    const socket = io('http://suporte.joyit.com.br:42003', {
        autoConnect: false,
        query: {
            id: route.params.CustomerID, 
            status: status              
        },
    
    })      


    const result = messages.map(({id, message, at}) => `${at} | ${id}: ${message}`).join('<br />')


    const sendArticle = async (State, CloseText) => {     
        let send = await Api.sendArticle(route.params.TicketID, '\"' + user.name + '\" <' + user.email + '>' , result + '<br /><br />' + CloseText, 'CHAT | ' + route.params.TicketNumber, State )
        if (send.ArticleID) {
            sendAnswerNotification(State)
        } else {
            alert("Dados não encotrados")
        }
    }

    const sendNotification = async (notification) => {     
        let send = await Api.sendTokens({
            "to": tokens,
            "sound": "default",
            "title": 'CHAT | ' + user.name + ' enviou:',
            "body": notification   
        })
        if (send.data) {
            
        } else {
          //  alert("Dados não encotrados")
        }
    }

    const sendAnswerNotification = async (State) => {   
        const successful = 'O Chat foi encerrado com êxito! O conteúdo do chat ficará registrado no chamado.'
        const unsuccessful = 'O Chat foi encerrado por falta de interação! O conteúdo do chat ficará registrado no chamado.'

        let send = await Api.sendTokens({
            "to":  tokens,
            "sound": "default",
            "title": 'Nova interação no chamado: ' + route.params.TicketNumber,
            "body": State === 'open' ? successful : unsuccessful 
        })
        if (send.data) {
            
        } else {
          //  alert("Dados não encotrados")
        }
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      const getChatTokens = async () => {
        try { let res = await Api.getChatTokens(route.params.CustomerID)           
            const tokenGet = res.map((obj) => {
                return obj.token
            })
            setTokens(tokenGet)
        } catch(err){
        //  alert("Dados não encotrados")
        }
      }
    
    useEffect(() => {

        const Init = () => {
            socket.open()             
            socket.emit('chat.message', {
                ID: user.id,
                open: 'true'
            })
        }
    
        Init()      
    }, [])



    useEffect(() => {

        getChatTokens()
        
        socket.open()
        const sendNewMessage = newMessage =>
        setMessages(newMessage)
        socket.on('chat.message', sendNewMessage)
        inputChat.current.focus()
        return () => socket.off('chat.message', sendNewMessage)
        
    }, [messages])

    const ButtonClick = async () => {
        socket.open()
        setStatus('true')
        if (message.trim()) {         
        socket.emit('chat.message', {
            ID: route.params.CustomerID,
            id: user.name,
            at: moment().format('DD-MM-YY HH:mm'),
            agentId: user.name,
            status: 'open',
            ticketNumber: route.params.TicketNumber,
            ticketTitle: route.params.Title,
            message
        })
        
        setMessage('')
        sendNotification(message)
    } 
}


    const ButtonGoBack = () => {  
        
         navigation.navigate('Tab');
    } 

    const ButtonClose = async () => {
        socket.open()
        setStatus('false')
        socket.emit('chat.message', {
            ID: route.params.CustomerID,
            status: 'close',
            }   
        )
        await sleep(2000)
        setStatus('false')
        sendArticle('open', '')
        socket.close()
        navigation.navigate('Tab')
        } 

    const ButtonClose2 = async () => {
        socket.open()
        setStatus('false')
        socket.emit('chat.message',  {
            ID: route.params.CustomerID,
            status: 'close',
            }   
        )
        await sleep(2000)
        setStatus('false')
        sendArticle('Aguardando Cliente',  'O Chat foi encerrado por falta de interação.')
        socket.close()
        navigation.navigate('Tab')
        }     



    return (
        <Container style={{backgroundColor: '#ffb400', padding: 40 }}>
            <HeaderArea>
            <GoBackButton  
                onPress={ButtonGoBack} >
                    <FontAwesome5 name='chevron-circle-left' size={40} color='white' />
                </GoBackButton>
                <GoBackButton  
                onPress={ButtonClose} >
                    <FontAwesome5 name='thumbs-up' size={40} color='white' />
                    <HeaderText>ENCERRAR CHAT</HeaderText>
                </GoBackButton>
                <GoBackButton  
                onPress={ButtonClose2} >
                    <FontAwesome5 name='pause' size={40} color='white' />
                    <HeaderText>ENCERRAR CHAT | SEM RESPOSTA</HeaderText>
                </GoBackButton>
                <HeaderText>
                   CHAT SOBRE : {route.params.TicketNumber} | {route.params.Title}
                </HeaderText>
            </HeaderArea>         
            <Scroller
            ref={scrollViewRef}
            onContentSizeChange={() => {
                scrollViewRef?.current?.scrollToEnd({ animated: true });
            }}
            >
            <ChatView >
            {messages.map((m, index)=>(    
            <ChatArea  key={index}  
                style={{alignItems: m.id === user.name ? 'flex-end' : 'flex-start'}}>
                <ChatText
                style={{backgroundColor: m.id === user.name ? '#2F4F4F' : 'gray'}}> 
                <ChatText style={{fontWeight: "bold", fontSize: 12, color: '#00FA9A'}}>
                {m.id} {"\n"}
                 </ChatText>
                 <ChatText style={{fontWeight: "normal"}}>
                 {m.message} {"\n"}
                </ChatText>
                <ChatText style={{fontStyle: 'italic', fontSize: 10, color: '#F5F5F5'}}>
                {m.at}
                </ChatText>                      
                </ChatText>
            </ChatArea>
            ))}
            </ChatView>                
            </Scroller >
            <InputArea >
            <Input  placeholderTextColor='#FFFFFF'
                             value={message}
                             onChangeText={t=>setMessage(t)}       
                             placeholder={'Digite aqui sua mensagem'}
                             onKeyPress={({ nativeEvent }) => {
                             nativeEvent.key === 'Enter' ? ButtonClick() : ''
                              }}
                              ref={inputChat}                                       
                             >                               
            </Input>
            <SendButton
                onPress={ButtonClick} >
                    <FontAwesome5 name={'play'} size={25} color={'white'} />                                  
            </SendButton>
            </InputArea>
            <KeyboardAvoidingView 
            behavior={Platform.OS === "ios" ? "padding" : "height"}>                    
            </KeyboardAvoidingView>       
        </Container>
    );
}

