import React, { useState } from 'react';
import { Keyboard } from 'react-native'
import { Container } from '../styles/styles';
import {useNavigation, useRoute} from '@react-navigation/native'
import { FontAwesome5 } from '@expo/vector-icons';
import Modal from '../components/Modal'

import styled from 'styled-components/native';

const InputArea = styled.SafeAreaView`
    width: 90%;
    height: 300px;
    margin-top: 40px;
    flex: 1;
    align-items: center;
`;

const InputText = styled.Text`
    font-size: 20px;
    color: #FFFFFF;
`;

const HeaderArea = styled.View`
    margin-top: 50px;
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;


const GoBackButton = styled.TouchableOpacity`
    height: 40px;
`;


const YesNoButton = styled.TouchableOpacity`
    height: 40px;
    border: 1px;
    width: 30%;
    borderColor: #FFFFFF;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    flex-direction: row;


`;

const YesNoButtonText = styled.Text`
    font-size: 20px;
    color: #FFFFFF;
    justify-content: center;
    margin-left: 10px;
`;



export default () => {



    const route = useRoute()
    const navigation = useNavigation()
    
    const [showModal, setShowModal] = useState(false)
    const [modalBody, setModalBody] = useState('')
    const [modalTitle, setModalTitle] = useState('')
    const [modalIcon, setModalIcon] = useState('')

    const ID = route.params.ID
    const subject = route.params.subject
    const body = route.params.body
    const image = route.params.selectedImage

    const ButtonClick = (service, SLA) => {
        Keyboard.dismiss()
        navigation.navigate('Sumary', {ID, subject, body, image, service, SLA});
    }

    const ButtonGoBack = () => {
        navigation.navigate('Wizard4');
    }


        const createTwoButtonAlert = () => {
        setModalTitle(' Lembre-se ! ')
        setModalBody( 'Para o chamado ser considerado de emergência, deve ser um servidor, ou no caso em que o incidente afeta mais que 2 usuários')  
        setModalIcon('bell')
        setShowModal(true)  
        
        }
         

    return (
        <Container style={{backgroundColor: '#ffb400' }}>
            <HeaderArea>
                <GoBackButton  
                onPress={ButtonGoBack} >
                    <FontAwesome5 name='chevron-circle-left' size={40} color='white' />
                </GoBackButton>
            </HeaderArea>
            <InputArea>
                <InputText>Esse é um chamado de emergêcia ?</InputText>
                <YesNoButton
                onPress={createTwoButtonAlert}>
                <YesNoButtonText>Sim</YesNoButtonText>                                   
                </YesNoButton>
                <YesNoButton
                onPress={() => ButtonClick('infra','SUP-R')} > 
                <YesNoButtonText>Não</YesNoButtonText>                                   
                </YesNoButton>
            </InputArea>
            <Modal
                show={showModal}
                setShow={setShowModal}
                title={modalTitle}
                body={modalBody}
                icon={modalIcon}
                bottom='1'
                bottomType='2'
                bottom1Text='Não é'
                bottom2Text='É emergência'
                modalBottom={ButtonClick}
            />       
        </Container>
    );
}