import React, { useState } from 'react';
import { Container } from '../styles/styles';
import {useNavigation, useRoute } from '@react-navigation/native'
import { FontAwesome5 } from '@expo/vector-icons';

import styled from 'styled-components/native';

const InputArea = styled.SafeAreaView`
    width: 90%;
    height: 300px;
    margin-top: 40px;
    flex: 1;
    align-items: center;


`;
const Input = styled.TextInput`
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 10px;
    backgroundColor: 'rgba(52, 52, 52, 0.1)'  
    width: 80%;
    border-radius: 15px;
    padding: 15px;
`;

const InputText = styled.Text`
    font-size: 20px;
    color: #FFFFFF;
`;

const HeaderArea = styled.View`
    margin-top: 50px;
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
`;


const GoBackButton = styled.TouchableOpacity`
    height: 40px;
    
`;

const NextButton = styled.TouchableOpacity`
    height: 40px;
    border: 1px;
    width: 30%;
    borderColor: #FFFFFF;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    

`;

const NextButtonText = styled.Text`
    font-size: 20px;
    color: #FFFFFF;
    justify-content: center;
    
`;



export default () => {

    const [subject, setSubject] = useState('');

    const navigation = useNavigation()
    const route = useRoute()
    
    const ID = route.params.ID

    const ButtonClick = () => {
        navigation.navigate('Wizard3', {ID, subject});
    }

    const ButtonGoBack = () => {
        navigation.navigate('Wizard1');
    }

    return (
        <Container style={{backgroundColor: '#ffb400' }}>
            <HeaderArea>
                <GoBackButton  
                onPress={ButtonGoBack} >
                    <FontAwesome5 name='chevron-circle-left' size={40} color='white' />
                </GoBackButton>
                {subject !== '' && 
                <NextButton
                onPress={ButtonClick}>
                    <NextButtonText>Confirmar</NextButtonText>                                   
                </NextButton>
                }
            </HeaderArea>
            <InputArea>
                <InputText>Qual o assunto da demanda ?</InputText>
                <Input  placeholderTextColor='#FFFFFF'
                             autoFocus={true}
                             maxLength={30}
                             value={subject}
                             onChangeText={t=>setSubject(t)}                                
                             >
                 </Input>
            </InputArea>
        </Container>
    );
}

