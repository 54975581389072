import React, { useState, useEffect, useContext } from 'react';
import { Container } from '../styles/styles';
import {useNavigation } from '@react-navigation/native'
import {RefreshControl, Image  } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import styled from 'styled-components/native';
import { FontAwesome5 } from '@expo/vector-icons';

import { UserContext } from '../contexts/UserContext';
import Api from '../Api'
import DashItem from '../components/DashItem'

const Scroller = styled.ScrollView`
    flex: 1;
    width: 100%
    padding: 10px

`;

const HeaderArea = styled.View`
    flex-direction: row
    justify-content: space-between
    align-items: center
    background-color: #1C1C1C
    width: 100%
    padding: 20px 20px 0px 20px     
`;

const HeaderTitle = styled.Text`
    font-size: 18px;
    color: #FFF;
`;

const SearchButton = styled.TouchableOpacity`
    width: 32px;
    height: 32px;
    margin-top: 0px
`;

const LoadingIcon = styled.ActivityIndicator`
    margin-top: 70px;
`;

const FilterArea = styled.View`
    flex-direction: row;
    justify-content: space-between;
    background-color: #696969
    width: 100%;
    align-items: center;
    padding: 7px 10px 7px 10px;
    border-bottom-width: 5px ;
    border-color: #03a9f4
    
    
`;

const DashArea = styled.View`
    flex-direction: row;
    justify-content: space-between;
    background-color: #1C1C1C
    width: 100%;
    align-items: center;
    padding: 10px 20px 15px 20px;   
            
`;

const TicketArea = styled.View`
    flex-direction: row;
    justify-content: space-around;
    width: 100%
    align-items: center;
`;

const TicketSubArea = styled.View`
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px
    
`;

const FilterButton = styled.TouchableOpacity`
    width: 200px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 10px
    background-color: #03a9f4 ;
    padding: 5px; 
    border-radius: 10px;
    height: 40px;

`;

const UserInfoArea = styled.View`
    padding: 0px;
    flex-direction: column;
    width: 170px;
    border-left-width: 2px ;
    border-color: #03a9f4 ;
    padding-right: 2px
    
`;

const UserInfoText = styled.Text`
    margin-left: 5px
    font-size: 14px;
    color: #FFF;
`;

const CoInfoText = styled.Text`

    margin-left: 5px
    font-size: 13px;
    color: #FFF;
`;


export default () => {


    const { state:user } = useContext(UserContext)
 
 
     const navigation = useNavigation()
     const [loading, setLoading] = useState(false);
     const [refreshing, setRefreshing] = useState(false);
 
     const Logoff = () => {
         AsyncStorage.removeItem('SessionID');
         navigation.reset({
             routes: [{name: 'AuthC'}]
         })
     }

     const News = () => {
        navigation.navigate('News')
    }
 
 
     const onRefresh = () => {
         setRefreshing(false);
                
     }

    return (
        <Container>  
                <HeaderArea>
                    <HeaderTitle>
                    <FontAwesome5 name='home' size={26} color={'#03a9f4'} /> | Dashboard </HeaderTitle>
                    <Image source={require('../../assets/app_img/logoFooter.png')} style={{ 
                    width: 100,
                    height: 30,
                    resizeMode: 'stretch',}}/>
                </HeaderArea>
                <DashArea>
                    <UserInfoArea>
                        <CoInfoText> 
                            <FontAwesome5 name='portrait' size={20} color={'white'} /> {user.name}
                        </CoInfoText>
                        <CoInfoText>
                            <FontAwesome5 name='building' size={18} color={'white'} /> {user.id}
                        </CoInfoText>
                    </UserInfoArea>
                    <SearchButton onPress={Logoff}>
                         <FontAwesome5 name='sign-in-alt' size={20} color={'white'}/>
                    </SearchButton>
                </DashArea>
                <FilterArea >
                    <HeaderTitle style={{fontSize: 15}}>Dashboard</HeaderTitle>
                </FilterArea>
                <DashArea style={{backgroundColor: '#F5F5F5', borderBottomWidth: 2, borderBottomColor: "#CFCFCF"}}>
                </DashArea>

                <TicketArea style={{backgroundColor: '#F5F5F5'}}>
                    <TicketSubArea>
                        <FilterButton onPress={News}>
                        <UserInfoText >Noticias</UserInfoText>
                        </FilterButton>
                        <FilterButton >
                        <UserInfoText>Planos</UserInfoText>                        
                        </FilterButton>
                    </TicketSubArea>
                    <TicketSubArea>
                        <FilterButton >
                        <UserInfoText>Cotas</UserInfoText>
                        </FilterButton>
                        <FilterButton >
                        <UserInfoText>Contratos</UserInfoText>
                        </FilterButton>
                    </TicketSubArea>
                </TicketArea>
                <FilterArea >
                    <HeaderTitle  style={{fontSize: 15}}>Noticias</HeaderTitle>
                </FilterArea>
                <Scroller 
                style={{backgroundColor: '#F5F5F5'}}
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                }>
                    {loading &&
                        <LoadingIcon size="large" color="#000000" />
                    }


            </Scroller>
            

        </Container>
    );
}

