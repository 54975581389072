export const initialState = {
    user: '',
    email: '',
    chat: [],
};

export const UserReducer = (state, action) => {
    
    switch (action.type) {
        case 'setUser':
                return { ...state,
                name: action.payload.name,
                email: action.payload.email 
            }
        case 'setChat':
                return { ...state, 
                chat: action.payload.chat 
                }

        default:
    return state;
}}