import React from 'react';
import styled from 'styled-components/native';

const InputArea = styled.View`
    width: 100%;
    height: 50px;
    background-color: #E8E8E8;
    flex-direction: row;
    border-radius: 15px;
    padding-left: 15px;
    align-items: center;
    margin-bottom: 15px;
`;
const Input = styled.TextInput`
    flex: 1;
    font-size: 16px;
    color: #268596;
    margin-left: 10px;
    outline-width: 0px
    inline-width: 0px
   
`;

export default ({placeholder, value, onChangeText, password, }) => {
    return (
        <InputArea>
            <Input
                placeholder={placeholder}
                placeholderTextColor="#000000"
                value={value}
                onChangeText={onChangeText}
                secureTextEntry={password}
            />
        </InputArea>
        

    );
}