import React from 'react';
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';

import Wizard1 from '../views/Wizard1'
import Wizard2 from '../views/Wizard2'
import Wizard3 from '../views/Wizard3'
import Wizard4 from '../views/Wizard4'
import Wizard5 from '../views/Wizard5'
import Sumary from '../views/Sumary'


const Wizard = createStackNavigator();


export default () => {
    return (
      <Wizard.Navigator screenOptions={{
        headerShown: false,
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
    }}>
      <Wizard.Screen name="Wizard1" component={Wizard1} />
      <Wizard.Screen name="Wizard2" component={Wizard2} />
      <Wizard.Screen name="Wizard3" component={Wizard3} />
      <Wizard.Screen name="Wizard4" component={Wizard4} />
      <Wizard.Screen name="Wizard5" component={Wizard5} />
      <Wizard.Screen name="Sumary" component={Sumary} />
    </Wizard.Navigator>
  );
  }