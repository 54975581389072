import React from 'react';
import styled from 'styled-components/native';
import { FontAwesome5 } from '@expo/vector-icons';
import {useNavigation, useRoute, useContext} from '@react-navigation/native'


const Area = styled.View`
    background-color: #FFFFFF;
    margin-bottom: 20px;
    border-radius: 10px;
    width: 100%

`;

const OptionsArea = styled.View`
    background-color: #FFFFFF;
    width: 100%
    padding: 5px 10px
    flex-direction: row;
`;

const Chat = styled.TouchableOpacity`
    width: 32px
    height: 32px

`;

const TitleArea = styled.TouchableOpacity`
    flex-direction: row;
    border-bottom-width: 2px ;
    border-color: #E8E8E8 ;
    padding: 7px;
    align-items: center;
    background-color: #FFF;
    
`;

const TitleTextArea = styled.View`
    flex-direction: column;
    padding-right: 20px;
`;

const NumberTitleText = styled.Text`
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    margin-left: 5px;
    padding: 0px;    
`;

const TitleText = styled.Text`
    font-size: 13px;
    color: #000000;
    margin-left: 5px;
    padding: 0px;    
`;

const InfoArea = styled.View`
    flexWrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    padding: 5px;
`;

const TicketItem = styled.Text`
    font-size: 14px;
    color: #000000;
    margin-left: 5px;
    padding: 1px;    
`;


const TicketItemDetail = styled.Text`
    font-size: 13px;
    font-weight: normal;
    color: #696969;

`;


export default ({Ticket}) => {

    const navigation = useNavigation()


    const ticketZoomBottom = () => {
        navigation.navigate('TicketZoom', {
            TicketID: Ticket.TicketID,
            Title: Ticket.Title,
        })

    }

    const goChat = () => {
        navigation.navigate('Chat', {
            TicketNumber: Ticket.TicketNumber,
            CustomerID: Ticket.CustomerID,
            Title: Ticket.Title,
            TicketID: Ticket.TicketID

        })

    }


    return ( 
        <Area >
            <TitleArea onPress={ticketZoomBottom}>
                <FontAwesome5 name='clipboard-check' size={35} color={
                    Ticket.StateType==='open' ? 'green' : Ticket.StateType==='closed' ? 'red' : Ticket.StateType==='new' ? 'blue' : 'orange'}/>
                <TitleTextArea>
                    <NumberTitleText>{Ticket.TicketNumber}</NumberTitleText> 
                    <TitleText numberOfLines={2}>{Ticket.Title}</TitleText> 
                </TitleTextArea> 
            </TitleArea>
            <InfoArea>
                 <TicketItem>ID do Cliente: <TicketItemDetail>{Ticket.CustomerID}</TicketItemDetail> </TicketItem> 
                 <TicketItem>Fila: <TicketItemDetail>{Ticket.Queue}</TicketItemDetail> </TicketItem> 
                 <TicketItem>SLA: <TicketItemDetail>{Ticket.EscalationTime}</TicketItemDetail> </TicketItem>
                 <TicketItem>Estado: <TicketItemDetail>{Ticket.State}</TicketItemDetail> </TicketItem>  
                 <TicketItem>Prioridade: <TicketItemDetail>{Ticket.Priority}</TicketItemDetail> </TicketItem> 
                 <TicketItem>Tipo: <TicketItemDetail>{Ticket.Type}</TicketItemDetail> </TicketItem>
                 <TicketItem>Criado: <TicketItemDetail>{Ticket.Created}</TicketItemDetail> </TicketItem> 
            </InfoArea>
            <OptionsArea>
            <Chat>
                <FontAwesome5 name='folder-open' size={24} color={'blue'}/>
            </Chat>
            <Chat onPress={goChat}>
                <FontAwesome5 name='comment' size={24} color={'red'}/>
            </Chat>
            </OptionsArea>
    
        </Area>
    );
}