import React, { useContext } from 'react';
import styled from 'styled-components/native';
import { Keyboard } from 'react-native'
import { FontAwesome5 } from '@expo/vector-icons';


const TabArea = styled.View`
    height: 60px;
    background-color: #f6f6f6;
    flex-direction: row;
`;
const TabItem = styled.TouchableOpacity`
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: row;

`;
const TabItemCenter = styled.TouchableOpacity`
    width: 70px;
    height: 70px;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    border-radius: 35px;
    border: 3px solid #ffb400;
    margin-top: -20px;
`;
const TabText = styled.Text`
    margin-left: 5px
    font-size: 14px;
    color: #000;
`;

export default ({ state, navigation }) => {

    const goTo = (screenName) => {
        Keyboard.dismiss()
        navigation.navigate(screenName);
    }

    return (
        <TabArea>
            <TabItem onPress={()=>goTo('Dashboard')}>
            <FontAwesome5 name='home' size={26} color={state.index=== 0 ? 'black' : '#DCDCDC'} />
            <TabText>Dashboard</TabText>
            </TabItem>
            <TabItem onPress={()=>goTo('Chamados')}>
            <FontAwesome5 name='user-alt' size={26} color={state.index=== 1 ? 'black' : '#DCDCDC'} />
            <TabText>Chamados</TabText>
            </TabItem>

        </TabArea>
    );
}