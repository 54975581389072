import React, { useState } from 'react';
import styled from 'styled-components/native';
import {useNavigation} from '@react-navigation/native'
import { FontAwesome5 } from '@expo/vector-icons';
import Modal from 'modal-react-native-web';


const ModalView = styled.Modal`` 


const ModalArea = styled.TouchableOpacity`
    flex: 1
    background-color: rgba(0, 0, 0, 0.0)
    justify-content: flex-end
    margin-bottom: -20px
    
`

const ModalContent = styled.View`
    background-color: #FFFFFF
    border-top-left-radius: 10px
    border-top-right-radius: 10px
    width: 40%;
    flex-direction: row;
        
`

const ModalTitleArea = styled.View`
    border-bottom-width: 2px 
    border-color: #E8E8E8 
    padding: 20px
    flex-direction: row
    align-items: center
    justify-content: center
    background-color: #FF6A6A
`

const ModalBodyArea = styled.ScrollView`
    padding: 20px 20px 20px 20px
`
const ModalTitleText = styled.Text`
    font-size: 17px;
    color: #000;
    font-weight: bold;
    margin-left: 10px
`

const ModalBodyText = styled.Text`
    font-size: 15px;
    color: #000;
    font-weight: normal;
`

const FirstBottom = styled.TouchableOpacity`
    height: 30px;
    border: 1px;
    width: 100px;
    borderColor: #000000;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    margin-top: 10px
`

const BottomText = styled.Text`
    font-size: 14px;
    color: #000000;
    justify-content: center;
`;

const BottomArea = styled.View`
    padding: 20px
    align-items: space-around
    justify-content: center
    margin-right: 10px

`

export default ({ show, setShow, title, body, icon, bottom, bottomType, bottom1Text, bottom2Text, modalBottom}) => {

    const navigation = useNavigation()

    const closeButtom = () => {
        setShow(false)
    }


    const modalBottom1 = () => {
        setShow(false)
        modalBottom('infra','SUP-R')
    }

    const modalBottom2 = () => {
        setShow(false)
        modalBottom('servidor','EM1-R')
    }

    console.log()

    return (
        <Modal style={{width: '40%'}}
            transparent={true}
            visible={show}
            animationType='fade'      
        >
            <ModalArea onPress={closeButtom}>            
            </ModalArea>
            <ModalContent>               
            <ModalTitleArea>
                <FontAwesome5 name={icon} size={30} color={'white'} />
                <ModalTitleText>
                {title}
                </ModalTitleText>
            </ModalTitleArea>
            <ModalBodyArea>
                <ModalBodyText>
                {body}
                </ModalBodyText>             
            </ModalBodyArea>
            {bottom === '1' &&
            <BottomArea>
            <FirstBottom
            onPress={modalBottom1}>
                <BottomText>
                {bottom1Text}    
                </BottomText>
            </FirstBottom>
            {bottomType === '2' &&
            <FirstBottom
            onPress={modalBottom2}>
                <BottomText>
                {bottom2Text}    
                </BottomText>
            </FirstBottom>
            }
            </BottomArea>
            }
            </ModalContent>
        </Modal>
    )
}