import AsyncStorage from '@react-native-async-storage/async-storage';

const CLIENT_API = 'http://suporte.joyit.com.br:42300';

export default {
    checkTokenCustomer: async () => {
        const token = await AsyncStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/session?token=${token}`)
        const sessionGet = await req.json();        
        return sessionGet;
    },

    loginCustomer: async (UserLogin, Password) => {
        const req = await fetch(`${CLIENT_API}/authAgent`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'email' : UserLogin,
                'password': Password
            })
        });
        const session = await req.json();        
        return session;
    },

    getServices: async () => {
        const token = await AsyncStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/plans?token=${token}`)
        const servicesGet = await req.json();        
        return servicesGet;
    },

    getNews: async () => {
        const token = await AsyncStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/news?token=${token}`)
        const newsGet = await req.json();        
        return newsGet;
    },

    sendNews: async (Subject, Type, Content, newsDate) => {
        const token = await AsyncStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/news?token=${token}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "subject": Subject,
                    "type": Type,
                    "content": Content,
                    "date": newsDate

                }
            )
        });
        const session = await req.json();        
        return session;
    },

    deleteNews: async (id) => {
        const token = await AsyncStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/news?token=${token}&id=${id}`, {
            method: 'DELETE'
        });
        const deleteN = await req.json();        
        return deleteN ;
    },

    getChatTokens: async (joyID) => {
        const token = await AsyncStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/appInfo?token=${token}&joyID=${joyID}`)
        const tokensGet = await req.json();
        return tokensGet;
    },

    getAllChatTokens: async () => {
        const token = await AsyncStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/allTokens?token=${token}`)
        const tokensGet = await req.json();
        return tokensGet;
    },
    
    

    sendTokens: async (messages) => {
        const token = await AsyncStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/appNotification?token=${token}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
               messages
            )
        });
        const session = await req.json();
        console.log(session)        
        return session;
    },

    getCustomerTickets: async (StateType='', CustomerUserLogin='') => {
        const token = await AsyncStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/tickets?token=${token}&customerUserLogin=${CustomerUserLogin}&stateType=${StateType}`);
        const ticket = await req.json();
        if (ticket) {
            return ticket
        } else {
            return 'nodata'
        } 
        
        
    },

    getCustomerTicketZoom: async (TicketID='') => {
        const token = await AsyncStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/ticketZoom?token=${token}&ticketID=${TicketID}`);
        const json = await req.json()
        return json ;
    },


    sendArticle: async (TicketID, To, Body, Subject, State) => {
        console.log('State:' + State)
        const token = await AsyncStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/articles?token=${token}&ticketID=${TicketID}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "Ticket": {
                    "State": State,
                    "PendingTime": {"Diff": "1440"}
                },

                "Article": {
                    "From": To,
                    "ContentType": "text/html; charset=UTF8",
                    "CommunicationChannel": "Phone",
                    "Subject": Subject,
                    "Body": Body,
                    "SenderType": "agent"
                }})
        });
        const article = await req.json()
        return article;
        

    },

    sendArticleImage: async (TicketID, To, Body, Subject, image) => {
        const token = await AsyncStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/articles?token=${token}&ticketID=${TicketID}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "Ticket": {
                    "State":"open",
                },
                "Article": {
                "From": To,
                "ContentType": "text/html; charset=UTF8",
                "CommunicationChannel": "Phone",
                "Subject": Subject,
                "Body": Body,
                "SenderType": "customer"
                },
                "Attachment" : {
                    "Content": image.base64,
                    "Filename": "anexo.jpg",
                    "ContentType": "image/jpg"
                }
                
            })
        });
        const articleImage = await req.json();        
        return articleImage;



    },

    

    openTicket: async (fullSubject, body, service, SLA) => {
        const customerUserLogin = await AsyncStorage.getItem('customerUserLogin');
        console.log(customerUserLogin)
        const token = await AsyncStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/openTicket?token=${token}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "Ticket": {
                    "Title": fullSubject ,
                    "Type":"Unclassified", 
                    "Queue":"Misc",
                    "State":"new",
                    "Service": service,
                    "SLA": SLA,
                    "Priority":"3 normal",
                    "CustomerUser": customerUserLogin
                }
                ,"Article": {
                    "ContentType": "text/html; charset=UTF8",
                    "Subject": fullSubject,
                    "Body": body,
                }                
                })
        });
        const ticket = await req.json();        
        return ticket;



    },

    openTicketImage: async (fullSubject, body, image, service, SLA) => {
        const customerUserLogin = await AsyncStorage.getItem('customerUserLogin');
        const token = await AsyncStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/openTicket?token=${token}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "Ticket": {
                    "Title": fullSubject ,
                    "Type":"Unclassified", 
                    "Queue":"Misc",
                    "State":"new",
                    "Service": service,
                    "SLA": SLA,
                    "Priority":"3 normal",
                    "CustomerUser": customerUserLogin
                }
                ,"Article": {
                    "ContentType": "text/html; charset=UTF8",
                    "Subject": fullSubject,
                    "Body": body,
                }
                ,"Attachment" : {
                    "Content": image.base64,
                    "Filename": "anexo.jpg",
                    "ContentType": "image/jpg"
                }
                

                
                })
        });
        const ticketImage = await req.json();        
        return ticketImage;



    }








};
    
    