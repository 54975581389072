import React, { useState, useEffect } from 'react';
import { Container } from '../styles/styles';
import {useNavigation, useRoute } from '@react-navigation/native'
import { FontAwesome5 } from '@expo/vector-icons';
import { RefreshControl } from 'react-native'
import styled from 'styled-components/native'
import {Picker} from '@react-native-picker/picker';
import moment from "moment/min/moment-with-locales"


import DashItem from '../components/DashItem'
import Api from '../Api'

const Scroller = styled.ScrollView`
    flex: 1;
    width: 100%
    padding: 10px

`;

const InputArea = styled.SafeAreaView`
    width: 70%;
    justify-content: center;
    align-items: center;
`;

const Input = styled.TextInput`
    margin-bottom: 10px;
    font-size: 16px;
    color: #FFFFFF;
    backgroundColor: 'rgba(52, 52, 52, 0.1)'  
    width: 100%;
    border-radius: 15px;
    padding: 10px;
`;

const Input2 = styled.TextInput`
    margin-bottom: 10px;
    font-size: 16px;
    color: #FFFFFF;
    backgroundColor: 'rgba(52, 52, 52, 0.1)'  
    width: 100%;
    border-radius: 15px;
    padding: 10px;
    height: 150px;

`;

const HeaderArea = styled.View`
    margin-top: 20px;
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
`;



const SendButton = styled.TouchableOpacity`
    height: 40px;
    border: 1px;
    width: 40px;
    borderColor: #FFFFFF;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    margin-left: 10px
    margin-top: 10px

`;


const GoBackButton = styled.TouchableOpacity`
    height: 40px;
    flex-direction: row;
    align-items: center;

`;


const LoadingIcon = styled.ActivityIndicator`
    margin-top: 70px;
`;

export default () => {


    const navigation = useNavigation()
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [type, setType] = useState('ATENDIMENTO');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [tokens, setTokens] = useState('')


    let newsDate = moment().format('DD-MM-YYYY')

     
    const getNews = async () => {
        try { let res = await Api.getNews()
            setList(res)
        } catch(err){
            alert("Dados não encotrados")
        }
    }


    useEffect(()=>{             
        getNews()
        getAllChatTokens()
                  
    },[]);

    const onRefresh = () => {
        setRefreshing(false);
               
    }


    const ButtonClick = async () => {
        let send = await Api.sendNews( subject, type, content, newsDate )
        //sendNotification(subject, type, content)
        getNews()

}

    const ButtonDash = async (id) => {
        let send = await Api.deleteNews( id )
        getNews()

    }



    const ButtonGoBack = () => {  
        navigation.navigate('Tab');

    } 

    const sendNotification = async (notificationSubject, notificationType, notificationContent) => {     
        let send = await Api.sendTokens({
            "to": tokens,
            "sound": "default",
            "title": 'NOTíCIAS | ' + notificationType ,
            "body": notificationSubject + '\n\n' + notificationContent   
        })
        if (send.data) {
            
        } else {
          //  alert("Dados não encotrados")
        }
    }

    const getAllChatTokens = async () => {
        try { let res = await Api.getAllChatTokens()           
            const tokenGet = res.map((obj) => {
                return obj.token
            })
            setTokens(tokenGet)
        } catch(err){
        //  alert("Dados não encotrados")
        }
      }


    return (
        <Container style={{backgroundColor: '#ffb400', padding: 10 }}>
            <HeaderArea>
            <GoBackButton  
                onPress={ButtonGoBack} >
                    <FontAwesome5 name='chevron-circle-left' size={40} color='white' />
                </GoBackButton>
            </HeaderArea>         
            <InputArea >
            <Input  placeholderTextColor='#FFFFFF'
                             placeholder={'Titulo da noticia'}
                             value={subject}
                             onChangeText={t=>setSubject(t)}
                             >                               
            </Input>
            <Input2  placeholderTextColor='#FFFFFF'
                             placeholder={'Descreva a noticia'}
                             value={content}
                             multiline={true}
                             onChangeText={t=>setContent(t)}
                             >                               
            </Input2>
            <Picker
            selectedValue={type}
            onValueChange={(itemValue, itemIndex) =>
                setType(itemValue)
            }>
            <Picker.Item label="ATENDIMENTO" value="ATENDIMENTO" />
            <Picker.Item label="CLOUD" value="CLOUD" />
            <Picker.Item label="INFRA" value="INFRA" />

            </Picker>
            <SendButton
                onPress={ButtonClick} >
                    <FontAwesome5 name={'play'} size={25} color={'white'} />                                  
            </SendButton>
            </InputArea>
            
            <Scroller 
                style={{backgroundColor: '#ffb400'}}
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                }>
                    {loading &&
                        <LoadingIcon size="large" color="#000000" />
                    }

            {list.map((item, k)=>(
                <DashItem
                key={k}
                icon={item.type === 'CLOUD' ? 'cloud' : item.type === 'INFRA' ? 'desktop' : item.type === 'ATENDIMENTO' ? 'comment-alt' : 'globe-americas'}
                color={item.type === 'CLOUD' ? '#87CEEB' : item.type === 'INFRA' ? '#008B8B' : item.type === 'ATENDIMENTO' ? '#FF4040' : 'gray'}
                title={item.subject}
                id={item.id}
                details={item.content}
                button={ButtonDash}
                >
                </DashItem>
                ))}

            </Scroller>
        </Container>
    );
}