import React, { useEffect, useState } from 'react';
import { Container } from '../styles/styles'
import {useNavigation, useRoute} from '@react-navigation/native'
import styled from 'styled-components/native';
import { FontAwesome5 } from '@expo/vector-icons';

import Api from '../Api'
import ArticleModal from '../components/ArticleModal'



const GoBackButton = styled.TouchableOpacity`
    margin-top: 0px
    height: 30px;
    margin-bottom: 10px
    
`;

const HeaderArea = styled.View`
    width: 100%
    padding: 15px
    background-color: #1c1c1c;
`;

const TitleText = styled.Text`
    font-size: 14px;
    color: #FFF;    
`;

const ArticlesArea = styled.ScrollView`
`;

const ArticleItem = styled.TouchableOpacity`
    width: 100% 
    padding: 10px
    flex-direction: row;
    align-items: center
    justify-content: space-between;
`;


const ArticlesText = styled.Text`
    font-size: 13px;
    color: #000;
    font-weight: bold;
    width: 85% 
  
`;

const ArticleFromText = styled.Text`
    font-size: 12px;
    color: #000;
    font-weight: normal;
`;

const ArticleFromArea = styled.View`
    width: 100%
    padding: 5px 20px 5px 2px
    
`;

const ArticleNumber = styled.View`
    background-color: #ffb400
    width: 60px
    height: 50px
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-bottom-left-radius: 10px
    border-top-left-radius: 10px 
`;

const ArticleStatus = styled.View`
    width: 5px
    height: 50px
    align-items: center;
    justify-content: center;
    margin-right: 3px
`;

const ArticleNumberText = styled.Text`
    font-size: 22px;
    color: #FFFFFF;
    margin-right: 5px
    font-weight: bold; 
`;

const Scroller = styled.View`
    flex: 1;
    width: 100%
    flex-direction: row;
    justify-content: space-around;
    background-color: #FFF;

`;


export default () => {    

    
    const navigation = useNavigation()
    const route = useRoute()
    const [loading, setLoading] = useState(false)
    const [ticketInfo, setTicketInfo] = useState('')
    const [selectedArticle, setSelectedArticle] = useState('0')
    const [showModal, setShowModal] = useState(false)

    const getCustomerTicketZoom = async () => {
        
        try { let json = await Api.getCustomerTicketZoom(route.params.TicketID);
        setTicketInfo(json.Ticket)}

        catch(err){
            // Perform error handling here
         }

    }   
   

    useEffect(()=>{

        getCustomerTicketZoom()
        

    }, []);

    const handlePress = (key) => {

        setSelectedArticle(key)
        setShowModal(true)
    }

    const ButtonGoBack = () => {
        navigation.navigate('Tab');
    }

    return (
        
        <Container >
            
            <HeaderArea>
            <GoBackButton  
                onPress={ButtonGoBack} >
                <FontAwesome5 name='chevron-circle-left' size={30} color='white' />
            </GoBackButton> 
                <TitleText>
                {route.params.Title}
                </TitleText>    
            </HeaderArea>
            <Scroller>
            <ArticlesArea
             style={{backgroundColor: '#F5F5F5'}} 
            showsVerticalScrollIndicator={false}>
                {ticketInfo[0]?.Article.map((item, key)=>(
                <ArticleItem  style={{backgroundColor: key === selectedArticle ? '#e5e5e5' : '#F5F5F5'}} key={key} onPress={() => handlePress(key)} >
                         <ArticleNumber >
                            <ArticleNumberText>
                            {item.ArticleNumber}
                            </ArticleNumberText>
                            <FontAwesome5 name={item.SenderType === 'agent' ? 'headset' : 'user-alt' } size={18} color='white' />
                        </ArticleNumber> 
                        <ArticleStatus style={{backgroundColor: item.SenderType === 'agent' ? 'red' : '#F5F5F5' }} >
                        </ArticleStatus>                       
                        <ArticleFromArea>
                            <ArticlesText>
                            {item.Subject}
                            </ArticlesText>
                            <ArticleFromText>
                            {item.From.replace(/"/g , '').replace(/<[^>]+>/g, '')}
                            </ArticleFromText>
                        </ArticleFromArea>
                </ArticleItem>                
            )).reverse()}
          
            </ArticlesArea>
            <ArticleModal
              ticket={ticketInfo}
              articleID={selectedArticle}
             />  
                     
            </Scroller>

    
        </Container>
    );
}

