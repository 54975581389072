import React from 'react';
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';

import AuthC from '../views/AuthC'
import Load from '../views/Load'
import Tab from '../routes/Tab'
import Wizard from '../routes/Wizard'
import TicketZoom from '../views/TicketZoom'
import Chat from '../views/Chat'
import News from '../views/News'


const Stack = createStackNavigator();

export default () => (
    <Stack.Navigator
        initialRouteName="Load"
        
        screenOptions={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
        }}
    >
        <Stack.Screen name="AuthC" component={AuthC} />
        <Stack.Screen name="Load" component={Load} />
        <Stack.Screen name="Tab" component={Tab} />
        <Stack.Screen name="Wizard" component={Wizard} /> 
        <Stack.Screen name="TicketZoom" component={TicketZoom} />
        <Stack.Screen name="Chat" component={Chat} /> 
        <Stack.Screen name="News" component={News} /> 

    </Stack.Navigator>
);