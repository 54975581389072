import React, { useState, useContext } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Container, 
    GoBlueButton, 
    ButtonText, 
    InputArea, 
    LogoEnd,   
        } from '../styles/styles';
import {useNavigation} from '@react-navigation/native'
import { Image } from 'react-native';
import Api from '../Api'
import SignInput from '../components/SignInput'
import Modal from '../components/Modal'


export default () => {

    const navigation = useNavigation()

    const [userField, setUserField] = useState('');
    const [passwordField, setPasswordField] = useState('');

    const [showModal, setShowModal] = useState(false)
    const [modalBody, setModalBody] = useState('')
    const [modalTitle, setModalTitle] = useState('')
    const [modalIcon, setModalIcon] = useState('')
    
    const ButtonClick = async () => {
        if(userField != '' && passwordField != '') {

            let session = await Api.loginCustomer(userField, passwordField);

            if(session.SessionID) {
                await AsyncStorage.setItem('SessionID', session.SessionID);

                navigation.reset({
                    routes:[{name:'Load'}]
                });
            } else {
                setModalTitle(' Erro !')
                setModalBody('Verifique seu usuário e senha')
                setModalIcon('exclamation-circle')
                setShowModal(true)
            }

        } else {
            setModalTitle(' Erro !')
                setModalBody('Preencha todos os campos')
                setModalIcon('exclamation-circle')
                setShowModal(true)
        }
    }


    return (
        <Container>
            <Image source={require('../../assets/app_img/logoHead.png')} style={{ 
                width: 170,
                height: 170,
                resizeMode: 'stretch',}}/>
            <InputArea>
            <SignInput
                    
                    placeholder="Usuário"
                    value={userField}
                    onChangeText={t=>setUserField(t)}
                />
                <SignInput
                    placeholder="Senha"
                    value={passwordField}
                    onChangeText={t=>setPasswordField(t)}
                    password={true}
                />
                <GoBlueButton onPress={ButtonClick}>
                    <ButtonText>Enviar</ButtonText>
                </GoBlueButton>
            </InputArea>
             <LogoEnd>
            <Image source={require('../../assets/app_img/logoFooter.png')} style={{ 
                width: 100,
                height: 30,
                resizeMode: 'stretch',}}/>
            </LogoEnd>
            <Modal
                show={showModal}
                setShow={setShowModal}
                title={modalTitle}
                body={modalBody}
                icon={modalIcon}
            />            
        </Container>
    );
}
