import React, { useState } from 'react'
import { Keyboard } from 'react-native'
import { Container } from '../styles/styles'
import {useNavigation, useRoute} from '@react-navigation/native'
import { FontAwesome5 } from '@expo/vector-icons'

import styled from 'styled-components/native'
import * as ImagePicker from 'expo-image-picker'


const InputArea = styled.SafeAreaView`
    width: 90%;
    height: 300px;
    margin-top: 40px;
    flex: 1;
    align-items: center;
`;

const InputText = styled.Text`
    font-size: 20px;
    color: #FFFFFF;
`;

const HeaderArea = styled.View`
    margin-top: 50px;
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const ImageArea = styled.Image`
    height: 300px
    width: 300px
    resizeMode: contain
    margin-top: 20px
    overflow: hidden
    border-radius: 15px;
`;


const GoBackButton = styled.TouchableOpacity`
    height: 40px;
`;

const LoadingIcon = styled.ActivityIndicator`
    margin-top: 50px;
`;


const YesNoButton = styled.TouchableOpacity`
    height: 40px;
    border: 1px;
    width: 30%;
    borderColor: #FFFFFF;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    flex-direction: row;


`;

const YesNoButtonText = styled.Text`
    font-size: 20px;
    color: #FFFFFF;
    justify-content: center;
    margin-left: 10px;
`;

const NextButtonText = styled.Text`
    font-size: 20px;
    color: #FFFFFF;
    justify-content: center;
`;

const NextButton = styled.TouchableOpacity`
    height: 40px;
    border: 1px;
    width: 30%;
    borderColor: #FFFFFF;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
`;



export default () => {

    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const route = useRoute()
    const navigation = useNavigation()

    const ID = route.params.ID
    const subject = route.params.subject
    const body = route.params.body




    const ButtonGoBack = () => {
        navigation.navigate('Wizard3');
    }

    const openImagePickerAsync = async () => {
        
        let result = await ImagePicker.requestCameraPermissionsAsync();
    
        if (result.granted === false) {
          alert("Permission to access camera roll is required!");
          return;
        }
        
        let pickerResult = await ImagePicker.launchCameraAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            aspect: [4, 3],
            quality: 0.3,
            base64: true,},
            setLoading(true) 
            )
               
        setSelectedImage(pickerResult);
        setLoading(false)            
        }
        

      const ButtonClick = () => {
        Keyboard.dismiss()
        navigation.navigate('Wizard5', {ID, subject, body, selectedImage});
    }

      

    return (
        <Container style={{backgroundColor: '#ffb400' }}>
            <HeaderArea>
                <GoBackButton  
                onPress={ButtonGoBack} >
                <FontAwesome5 name='chevron-circle-left' size={40} color='white' />
                </GoBackButton> 
                <NextButton
                onPress={ButtonClick}>
                    <NextButtonText>{selectedImage  === null ? 'Não é necessário' : 'Continuar' }</NextButtonText>                                   
                </NextButton>
            </HeaderArea>
            <InputArea>
                <InputText>Deseja anexar uma foto a solicitação ?</InputText>
                <YesNoButton
                onPress={openImagePickerAsync}>
                <YesNoButtonText>Anexar foto</YesNoButtonText>                                   
                </YesNoButton>
                {loading &&
                    <LoadingIcon size="large" color="#FFFFFF" />
                }
                {selectedImage !== null && 
                <ImageArea   source={{ uri: selectedImage.uri }}>     
                </ImageArea>
                } 
            </InputArea>
        </Container>
    );
}