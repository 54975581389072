import React from 'react'
import { NavigationContainer } from '@react-navigation/native'
import Stack from './src/routes/Stack'
import UserContextProvider from './src/contexts/UserContext'

export default () => {
  return (
    <UserContextProvider>
      <NavigationContainer>
        <Stack />
      </NavigationContainer>
    </UserContextProvider>
    
  );
}