import React, { useState } from 'react';
import { Container } from '../styles/styles';
import {useNavigation} from '@react-navigation/native'
import { FontAwesome5 } from '@expo/vector-icons';


import styled from 'styled-components/native';

const InputArea = styled.SafeAreaView`
    width: 90%;
    margin-top: 40px;
    align-items: center;
    flex: 1;    

`;

const Input = styled.TextInput`
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 10px;
    backgroundColor: 'rgba(52, 52, 52, 0.1)'  
    width: 30%;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 10px
`;

const InputText = styled.Text`
    font-size: 20px;
    color: #FFFFFF;
`;

const HeaderArea = styled.View`
    margin-top: 50px;
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
`;


const GoBackButton = styled.TouchableOpacity`
    height: 40px;
    
`;

const NextButton = styled.TouchableOpacity`
    height: 40px;
    border: 1px;
    width: 30%;
    borderColor: #FFFFFF;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
`;

const NextButtonText = styled.Text`
    font-size: 20px;
    color: #FFFFFF;
    justify-content: center;
`;

const OptionsButton = styled.TouchableOpacity`
    height: 40px;
    border-radius: 15px;
    width: 30%;
    align-items: center;
    justify-content: center;
    margin-top: 20px
    backgroundColor: 'rgba(52, 52, 52, 0.1)'
`;

const OptionsButtonText = styled.Text`
    font-size: 20px;
    color: #FFFFFF;
    justify-content: center;
`;



export default () => {

    const [ID, setID] = useState('');

    const navigation = useNavigation()
   

    const ButtonClick = (ID) => {
        navigation.navigate('Wizard2', {ID});
    }

    const ButtonGoBack = () => {
        navigation.navigate('Tab');
    }

    return (
        <Container style={{backgroundColor: '#ffb400' }}>
            <HeaderArea>
                <GoBackButton  
                onPress={ButtonGoBack} >
                    <FontAwesome5 name='chevron-circle-left' size={40} color='white' />
                </GoBackButton> 
                {ID !== '' &&
                <NextButton
                onPress={() => ButtonClick(ID)}>
                    <NextButtonText>Confirmar</NextButtonText>                                   
                </NextButton>
                }
            </HeaderArea>
            <InputArea>
                <InputText>Qual o ID do equipamento que necessita de atendimento?</InputText>
                <Input  placeholderTextColor='#FFFFFF'
                             autoFocus={false}
                             maxLength={12}                             
                             placeholder='XXXX-XX-0000'
                             value={ID}
                             onChangeText={t=>setID(t)}                               
                             >
                </Input>
                <InputText>Não possui o id de identificação ? Use uma das opções abaixo:</InputText>
                <OptionsButton
                     onPress={() => ButtonClick('CLOUD')}>
                    <OptionsButtonText>Cloud</OptionsButtonText>                                   
                </OptionsButton>
                <OptionsButton
                     onPress={() => ButtonClick('INFRA')}>
                    <OptionsButtonText>Infraestrutura</OptionsButtonText>                                   
                </OptionsButton>
                <OptionsButton
                     onPress={() => ButtonClick('SERVIDOR')}>
                    <OptionsButtonText>Servidor</OptionsButtonText>                                   
                </OptionsButton>
                <OptionsButton
                     onPress={() => ButtonClick('DESENV')}>
                    <OptionsButtonText>Desenvolvimento</OptionsButtonText>                                   
                </OptionsButton>    
    
            </InputArea>
        </Container>
    );
}

