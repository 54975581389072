import React from 'react';
import styled from 'styled-components/native';

export const Container = styled.View`
    background-color: #1C1C1C;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 15px
`;

export const GoButton = styled.TouchableOpacity`
    height: 60px;
    background-color: #ffb400;
    border-radius: 20px;
    justify-content: center;
    align-items: center;

`;

export const GoBlueButton = styled.TouchableOpacity`
    height: 60px;
    background-color: #03a9f4;
    border-radius: 20px;
    justify-content: center;
    align-items: center;

`;

export const ButtonText = styled.Text`
    font-size: 16px;
    color: #FFFFFF;
`;

export const InputArea = styled.View`
    width: 30%;
    padding: 20px;
`;

export const Title = styled.Text`
    font-size: 16px;
    color: #FFFFFF;
`;

export const ButtonTextBold = styled.Text`
    font-size: 16px;
    color: #268596;
    font-weight: bold;
    margin-left: 5px;
`;

export const ButtonTextChange = styled.Text`
    font-size: 16px;
    color: #FFFFFF;
    font-weight: bold;
    margin-left: 5px;
`;

export const AgentButton = styled.TouchableOpacity`
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 20px;
`;

export const LogoEnd = styled.View`
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 20px;
`;

export const LoadingIcon = styled.ActivityIndicator`
    margin-top: 50px;
`;