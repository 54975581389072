import React, { useState } from 'react';
import { Container } from '../styles/styles';
import {useNavigation, useRoute} from '@react-navigation/native'
import { FontAwesome5 } from '@expo/vector-icons';
import styled from 'styled-components/native';
import Api from '../Api'
import Modal from '../components/Modal'

const InputArea = styled.SafeAreaView`
    width: 90%;
    height: 300px;
    margin-top: 40px;
    flex: 1;
    align-items: flex-start;
`;

const InputText = styled.Text`
    font-size: 24px;
    color: #FFFFFF;
`;

const DetailText = styled.Text`
    font-size: 15px;
    color: #000000;
    margin-top: 10px
`;

const DetailTextContent = styled.Text`
    font-size: 20px;
    color: #FFFFFF;
    margin-top: 5px
`;

const HeaderArea = styled.View`
    margin-top: 50px;
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const ImageArea = styled.Image`
    height: 200px
    width: 200px
    resizeMode: contain
    margin-top: 20px
    overflow: hidden
    border-radius: 15px;
`;


const GoBackButton = styled.TouchableOpacity`
    height: 40px;
`;

const NextButton = styled.TouchableOpacity`
    height: 40px;
    border: 1px;
    width: 30%;
    borderColor: #FFFFFF;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
`;

const NextButtonText = styled.Text`
    font-size: 20px;
    color: #FFFFFF;
    justify-content: center;
`;



export default () => {

    const route = useRoute()
    const navigation = useNavigation()
   
    const [showModal, setShowModal] = useState(false)
    const [modalBody, setModalBody] = useState('')
    const [modalTitle, setModalTitle] = useState('')
    const [modalIcon, setModalIcon] = useState('')

    let fullSubject = route.params.ID + ' | ' + route.params.subject

    const ButtonClick = () => {
        navigation.navigate('Tab');
    }

    const ButtonGoBack = () => {
        navigation.navigate('Wizard3');
    }


    const openTicket = async () => {
        
        if (route.params.image === null) 
            {
            let send = await Api.openTicket(fullSubject, route.params.body, route.params.service, route.params.SLA ); 
            if (send.ArticleID) {
                setModalTitle(' O chamado foi criado com sucesso! ')
                setModalBody( 'Sua solicitação foi registrada em nosso sistema\n\nO chamado numero: ' + send.TicketNumber + ' foi criado!')  
                setModalIcon('chevron-circle-right')
                setShowModal(true)
                ButtonClick()
            } else {
                setModalTitle(' Erro !')
                setModalBody('Ocorreu um erro ao abrir o chamado')
                setModalIcon('exclamation-circle')
                setShowModal(true)
            }}
        else {
            let send = await Api.openTicketImage(fullSubject, route.params.body, route.params.image, route.params.service, route.params.SLA);
            if (send.ArticleID) {
                setModalTitle(' O chamado foi criado com sucesso! ')
                setModalBody( 'Sua solicitação foi registrada em nosso sistema\n\nO chamado numero: ' + send.TicketNumber + ' foi criado!')  
                setModalIcon('chevron-circle-right')
                setShowModal(true)
                ButtonClick()
            } else {
                setModalTitle(' Erro !')
                setModalBody('Ocorreu um erro ao abrir o chamado')
                setModalIcon('exclamation-circle')
                setShowModal(true)
            }
            
        }

    }

    return (
        <Container style={{backgroundColor: '#ffb400' }}>
            <HeaderArea>
                <GoBackButton  
                onPress={ButtonGoBack} >
                    <FontAwesome5 name='chevron-circle-left' size={40} color='white' />
                </GoBackButton> 
                <NextButton
                onPress={openTicket}>
                 <NextButtonText>Abrir o chamado</NextButtonText>                                   
                </NextButton>
            </HeaderArea>
            <InputArea>
                <InputText>Detalhes de sua solicitação</InputText>
                <DetailText>Assunto: </DetailText>
                <DetailTextContent> {fullSubject} </DetailTextContent>
                <DetailText>Descrição: </DetailText>
                <DetailTextContent> {route.params.body} </DetailTextContent>
                <DetailText>Serviço: </DetailText>
                <DetailTextContent> {route.params.service} </DetailTextContent>
                <DetailText>SLA: </DetailText>
                <DetailTextContent> {route.params.SLA} </DetailTextContent>
                <DetailText>Anexo: </DetailText>
                {route.params.image !== null && 
                <ImageArea source={{ uri: route.params.image.uri }}>
                </ImageArea>
                
                }
            </InputArea>
            <Modal
                show={showModal}
                setShow={setShowModal}
                title={modalTitle}
                body={modalBody}
                icon={modalIcon}
            />            
        </Container>
    );
}