import React, { useState, useEffect,  useContext } from 'react';
import { Container, LoadingIcon, GoButton, ButtonText } from '../styles/styles';
import { Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Api from '../Api'

import { UserContext } from '../contexts/UserContext';


export default () => {

    const navigation = useNavigation();
    const { dispatch } = useContext(UserContext);
    
    useEffect(()=>{
       
    const nextView = async () => {
        const res = await Api.checkTokenCustomer()

        if(res.Error) {
            navigation.reset({
                routes:[{name:'AuthC'}]
            });
        } else {
            let userFullName = res.SessionData.find(x => x.Key == 'UserFullname')
            let userEmail = res.SessionData.find(x => x.Key == 'UserEmail')

            dispatch({
                type: 'setUser',
                payload:{
                    name: userFullName.Value,
                    email: userEmail.Value,             
             
                },
            });
            navigation.navigate('Tab');
        }
    }
        
        nextView() 
        
    }, []);



    return (
        <Container>
            <Image source={require('../../assets/app_img/logoHead.png')} style={{ 
                width: 200,
                height: 200,
                resizeMode: 'stretch',}}/>
            <LoadingIcon size="large" color="#FFFFFF" />
        </Container>
    );
}
