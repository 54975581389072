import React, { useState, useEffect, useContext } from 'react';
import { Container } from '../styles/styles';
import {useNavigation } from '@react-navigation/native'
import {RefreshControl, Image } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import styled from 'styled-components/native';
import { FontAwesome5 } from '@expo/vector-icons';

import { UserContext } from '../contexts/UserContext';
import Api from '../Api'
import TicketItem from '../components/TicketItem'

const Scroller = styled.ScrollView`
    flex: 1;
    width: 100%
    padding: 10px

`;

const HeaderArea = styled.View`
    flex-direction: row
    justify-content: space-between
    align-items: center
    background-color: #1c1c1c
    width: 100%
    padding: 20px 20px 0px 20px     
`;

const HeaderTitle = styled.Text`
    font-size: 18px;
    color: #FFF;
`;

const SearchButton = styled.TouchableOpacity`
    width: 32px;
    height: 32px;
    margin-top: 0px
`;

const LoadingIcon = styled.ActivityIndicator`
    margin-top: 70px;
`;

const FilterArea = styled.View`
    flex-direction: row;
    justify-content: space-between;
    background-color: #696969
    width: 100%;
    align-items: center;
    padding: 7px 10px 7px 10px;
    border-bottom-width: 5px ;
    border-color: #03a9f4
    
    
`;

const DashArea = styled.View`
    flex-direction: row;
    justify-content: space-between;
    background-color: #1c1c1c
    width: 100%;
    align-items: center;
    padding: 10px 20px 15px 20px;   
        
`;


const FilterButton = styled.TouchableOpacity`
    height: 18px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 10px 
    padding: 10px

`;


const UserInfoArea = styled.View`
    padding: 0px;
    flex-direction: column;
    width: 250px;
    border-left-width: 2px ;
    border-color: #03a9f4 ;
    padding-right: 2px
`;

const CoInfoText = styled.Text`

    margin-left: 5px
    font-size: 13px;
    color: #FFF;
`;

const PlansText = styled.Text`
    margin-left: 5px
    font-size: 14px;
    color: #000;

`;


const ListArea = styled.View`
    padding: 15px;
    justify-content: center;
    align-items: flex-start;

`;

const EmptyText = styled.Text` 
    font-size: 16px;
    color: #CFCFCF;
    justify-content: center;
    align-items: center;
`;

let StateType = 'open'



export default () => {

    const { state:user } = useContext(UserContext)

    const navigation = useNavigation()
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [empty, setEmpty] = useState(null);
  
 

    const Logoff = () => {
        AsyncStorage.removeItem('SessionID');
        navigation.reset({
            routes: [{name: 'AuthC'}]
        })
    }
  

    const setStateTicket = (StateTicket) => {
        setList([])
        StateType = StateTicket
        getCustomerTickets()
        
        }
    


    const getCustomerTickets = async () => {
        setLoading(true)
        let res2 = await Api.getCustomerTickets(StateType, user.user); 
        if (res2.Ticket) {
            setEmpty(false)
            setList(res2.Ticket)
                      
        } else {
            setEmpty(true)
        }
        setLoading(false);
    }

    useEffect(()=>{

        setStateTicket('open')
        
    }, []);

    const onRefresh = () => {
        setRefreshing(false);
        setList([])
        getCustomerTickets();

        
    }

    return (
        <Container>
                <HeaderArea>
                    <HeaderTitle>
                    <FontAwesome5 name='user-alt' size={20} color={'#03a9f4'} /> | Chamados </HeaderTitle>
                    <Image source={require('../../assets/app_img/logoFooter.png')} style={{ 
                    width: 100,
                    height: 30,
                    resizeMode: 'stretch',}}/>
                </HeaderArea>
                <DashArea>
                    <UserInfoArea>
                        <CoInfoText> 
                            <FontAwesome5 name='portrait' size={20} color={'white'} /> {user.name}
                        </CoInfoText>
                        <CoInfoText>
                            <FontAwesome5 name='building' size={18} color={'white'} /> {user.id}
                        </CoInfoText>
                    </UserInfoArea>
                    <SearchButton onPress={Logoff}>
                         <FontAwesome5 name='sign-in-alt' size={20} color={'white'}/>
                    </SearchButton>
                </DashArea>  
                <FilterArea >
                    <HeaderTitle style={{fontSize: 15}}>Filtros</HeaderTitle>
                </FilterArea>              
               
                <DashArea  style={{backgroundColor: '#F5F5F5', justifyContent: 'flex-end'}}>
                    <FilterButton onPress={()=>setStateTicket('new')} >
                    <FontAwesome5  name='folder-plus' size={22} color={StateType === 'new' ? 'black' : '#CFCFCF' }/>
                    <PlansText style={{color:StateType === 'new' ? 'black' : '#CFCFCF'}}>Novos</PlansText>
                    </FilterButton>
                    <FilterButton onPress={()=>setStateTicket('open')} >
                    <FontAwesome5  name='folder-open' size={22} color={StateType === 'open' ? 'black' : '#CFCFCF' }/>
                    <PlansText style={{color:StateType === 'open' ? 'black' : '#CFCFCF'}}>Abertos</PlansText>
                    </FilterButton>
                    <FilterButton onPress={()=>setStateTicket('closed')}>
                    <FontAwesome5 name='window-close' size={22} color={StateType === 'closed' ? 'black' : '#CFCFCF' } />
                    <PlansText style={{color:StateType === 'closed' ? 'black' : '#CFCFCF'}}>Fechados</PlansText>
                    </FilterButton>
                    <FilterButton onPress={()=>setStateTicket('pending reminder')}>
                    <FontAwesome5 name='pause-circle' size={22} color={StateType === 'pending reminder' ? 'black' : '#CFCFCF' } />
                    <PlansText style={{color:StateType === 'pending reminder' ? 'black' : '#CFCFCF'}}>Pendentes</PlansText>
                    </FilterButton>
                    <FilterButton onPress={()=>setStateTicket('')} >
                    <FontAwesome5 name='clone' size={22} color={StateType === '' ? 'black' : '#CFCFCF' } />
                    <PlansText style={{color:StateType === '' ? 'black' : '#CFCFCF'}}>Todos</PlansText>
                    </FilterButton>
                </DashArea>
                <FilterArea >
                    <HeaderTitle style={{fontSize: 15}}>Chamados</HeaderTitle>
                </FilterArea>            
                <Scroller 
                 style={{backgroundColor: '#F5F5F5'}}
                refreshControl={
                <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                }>
                 {empty === true &&
                    <ListArea>
                    <FontAwesome5 name='bell-slash' size={60} color={'#CFCFCF'} />
                    <EmptyText> Nenhum item disponivel para exibição </EmptyText>
                    </ListArea>
                }
           

                {loading &&
                    <LoadingIcon size="large" color="#000000" />
                }

                <ListArea>
                    {list.map((item, k)=>(
                        <TicketItem key={k} Ticket={item} />
                    ))}
                    
                    
                </ListArea>



            </Scroller>

        </Container>
    );
}
        