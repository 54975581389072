import React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

import CustomTabBar from '../components/CustomTabBar'

import MyTickets from '../views/MyTickets'
import Home from '../views/Home'



const Tab = createBottomTabNavigator()

export default () => (    

  <Tab.Navigator tabBar={props=><CustomTabBar {...props} />}>
  <Tab.Screen  name="Dashboard" component={Home}  />
  <Tab.Screen name="Chamados" component={MyTickets} options={{tabBarBadge: 3}} />
</Tab.Navigator>
    )
  

  



