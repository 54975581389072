import React, { useState } from 'react';
import styled from 'styled-components/native';
import {useNavigation} from '@react-navigation/native'
import { FontAwesome5, Ionicons } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker'
//import Modal from 'modal-react-native-web';



import Api from '../Api'


const ModalContent = styled.View`
    padding: 20px
    width: 70%;
    backgroundColor: #FFFFFF
    border-left-width: 1px ;
    border-color: #ffb400
        
`
const LoadingIcon = styled.ActivityIndicator`
    margin-top: 0px;
`;

const ModalHeader = styled.View`
    border-bottom-width: 4px ;
    border-color: #E8E8E8 
    align-items: flex-start

`

const ModalSubject = styled.View`
    border-bottom-width: 2px ;
    border-color: #E8E8E8 ;
    padding: 10px 0px 10px 0px
`

const OptionsArea = styled.View`
    border-color: #E8E8E8 ;
    flex-direction: row;
    justify-content: space-between
    align-items: center
    margin-bottom: 5px
`
const AnexoArea = styled.View`
    border-color: #E8E8E8 ;
    flex-direction: row;
    flex: 1
    align-items: center
`

const IconsArea = styled.View`
    border-color: #E8E8E8 ;
    padding: 5px
    flex-direction: row;
`

const ModalBody = styled.ScrollView`
    padding: 15px 0px 30px 0px
`
const ModalBodyText = styled.Text`
    font-size: 16px;
    color: #000;
    font-weight: bold;
`
const OptionsText = styled.Text`
    font-size: 14px;
    color: #000;
    font-weight: normal;
    height: 30px
`

const ModalItemText = styled.Text`
    font-size: 14px;
    color: #000;
    font-weight: normal;
`
const Input = styled.TextInput`
    font-size: 15px;
    color: #000000;
    backgroundColor: #F5F5F5; 
    width: 100%;
    border-radius: 5px;
    padding: 10px
    margin-top: 10px;
    height: 120px;
`
const SendBottom = styled.TouchableOpacity`
    height: 30px
    width: 30px
    align-items: center
    justify-content: center
    margin-left: 10px
`
const ImageArea = styled.Image`
    height: 30px
    width: 30px
    resizeMode: contain
    overflow: hidden
    border-radius: 5px;
`;

export default ({ show, setShow, ticket, articleID }) => {

    const navigation = useNavigation()
    const [Body, setBody] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false);


    const sendArticle = async () => {

        if ( selectedImage === null) {

        let send = await Api.sendArticle(ticket[0]?.TicketID, ticket[0]?.Article[articleID]?.From, Body, ticket[0]?.Article[articleID]?.Subject); 
        if (send.ArticleID) {
        } else {
            alert("Dados não encotrados")
        }}

        else {
        let send = await Api.sendArticleImage(ticket[0]?.TicketID, ticket[0]?.Article[articleID]?.From, Body, ticket[0]?.Article[articleID]?.Subject, selectedImage); 
        console.log(send)       
        if (send.ArticleID) {
        } else {
            alert("Dados não encotrados")
        }

    }

    }

    const openImagePickerAsync = async () => {
        
        let result = await ImagePicker.requestCameraPermissionsAsync();
    
        if (result.granted === false) {
          alert("A permissão para o uso da camera é requerida!");
          return;
        }
        
        let pickerResult = await ImagePicker.launchCameraAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            aspect: [4, 3],
            quality: 0.3,
            base64: true,},
            setLoading(true) 
            )
               
        setSelectedImage(pickerResult);
        setLoading(false)            
        }

        
    return (
            <ModalContent >            
            {ticket[0]?.Article[articleID]?.SenderType === 'agent' && 
            <ModalHeader>
            <ModalBodyText>
                Responder:
            </ModalBodyText>
            <Input  placeholderTextColor='#000000' 
                    multiline={true} 
                    value={Body}
                    onChangeText={t=>setBody(t)}  >
            </Input>
            <OptionsArea>
                <AnexoArea>
                    <OptionsText>
                    <Ionicons name='ios-attach' size={30} color={'gray'} />
                    </OptionsText>
                    {loading &&
                    <LoadingIcon size="small" color="#000000" />
                    }
                    {selectedImage !== null && 
                    <ImageArea   source={{ uri: selectedImage.uri }}>     
                    </ImageArea>    
                    }

                </AnexoArea>
                <IconsArea>
                    <SendBottom  onPress={openImagePickerAsync} >
                    <FontAwesome5 name='camera' size={30} color={'gray'} /> 
                    </SendBottom>
                    <SendBottom  onPress={sendArticle} disabled={Body === '' ? true : false} >
                    <FontAwesome5 name='play' size={30} color={Body === '' ? 'gray' : 'green'} /> 
                    </SendBottom>
                </IconsArea>
            </OptionsArea>
            </ModalHeader> }
            <ModalSubject>
                <ModalBodyText>
                {ticket[0]?.Article[articleID]?.Subject}
                </ModalBodyText>
            </ModalSubject>
            <ModalBody>
                    <ModalItemText>
                    {ticket[0]?.Article[articleID]?.Body}
                    </ModalItemText>             
            </ModalBody>
            </ModalContent>
    )}