import React from 'react';
import styled from 'styled-components/native';
import { FontAwesome5 } from '@expo/vector-icons';
import {useNavigation, useRoute} from '@react-navigation/native'

import Api from '../Api'


const Area = styled.View`
    background-color: #FFFFFF;
    margin-bottom: 20px;
    border-radius: 10px;
`;

const TitleArea = styled.View`
    flex-direction: row;
    border-bottom-width: 2px ;
    border-color: #E8E8E8 ;
    padding: 7px;
    align-items: center;
    background-color: #F5F5F5;
    
`;

const TitleTextArea = styled.View`
    flex-direction: column;
    padding-right: 20px;
`;


const TitleText = styled.Text`
    font-size: 16px;
    color: #000000;
    margin-left: 5px;
    padding: 0px;    
`;

const InfoArea = styled.View`
    flexWrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
`;

const TicketItemDetail = styled.Text`
    font-size: 13px;
    font-weight: normal;
    color: #696969;

`;

const Chat = styled.TouchableOpacity`
    width: 32px
    height: 32px

`;

const OptionsArea = styled.View`
    background-color: #FFFFFF;
    width: 100%
    padding: 5px 10px
    flex-direction: row;
`;


export default ({id, color, icon, title, details, button}) => {

    const navigation = useNavigation()

    const button1 = async () => {       
         
        button(id)

    }


    return ( 
        <Area >
            <TitleArea>
                <FontAwesome5 name={icon} size={26} color={color}/>
                <TitleTextArea>
                    <TitleText numberOfLines={3}>{title}</TitleText> 
                </TitleTextArea> 
            </TitleArea>
            <InfoArea>
                 <TicketItemDetail textAlign='justify' >{details}</TicketItemDetail> 
            </InfoArea>
            <OptionsArea>
            <Chat>
                <FontAwesome5 name='pen-square' size={24} color={'gray'}/>
            </Chat>
            <Chat onPress={button1}>
                <FontAwesome5 name='minus-square' size={24} color={'gray'}/>
            </Chat>
            </OptionsArea>
        </Area>
    );
}